import { Controller } from "@hotwired/stimulus"
import { Modal } from 'flowbite';

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element, {
      keyboard: false
    })
    this.modal.show()
    this.element.addEventListener('turbo:submit-end', (event) => {
      console.log(event);
      if (event.detail.success) {
          this.modal.hide();
      }
    });
  }

  hideModal(){
    this.modal.hide()
  }

  disconnect() {
    this.modal.hide()
  }
}
